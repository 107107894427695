@import '../styles/constants';
@import '../styles/colors';
@import '../styles/mixins';

.loading-or-error-page {
  @include standard-page-wrapper;
}

.admin-page {
  width: 100%;
  max-width: calc(#{$content-max-width} + 2rem);
}

.admin-page__heading {
  margin: 0;
  padding: 1rem 0 0 1rem;

  @media(min-width: $mobile-breakpoint) {
    padding: 2rem 0 0 2rem;
  }

  @media (min-width: calc(#{$content-max-width} + 64px)) {
    padding: 2rem 0 0 1rem;
  }
}

.admin-page__users-table {
  overflow-x: auto;
  padding: 1rem;

  @media(min-width: $mobile-breakpoint) {
    padding: 1rem 2rem;
  }

  @media (min-width: calc(#{$content-max-width} + 64px)) {
    padding: 1rem 1rem;
  }
}

.checkmark {
  font-size: 1rem;
  width: min-content;
  border-radius: 50%;
  padding: 0.1rem;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark--true {
  color: $success-green;
  background-color: rgba($success-green, 0.1);
}

.checkmark--false {
  color: $danger-red;
  background-color: rgba($danger-red, 0.1);
}