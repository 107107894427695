@import '../styles/colors';
@import '../styles/constants';
@import '../styles/mixins';

.navbar-container {
  position: relative;
  z-index: $navbar-z-index;
  display: flex;
  justify-content: center;
  background-color: $main;
  height: $navbar-height;
  box-shadow: 0px 1px 1px rgba(46, 46, 46, 0.2);
  font-weight: 500;
  letter-spacing: 0.2px;
}

.navbar {
  max-width: $content-max-width;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.navbar__logo {
  padding: 0.7rem;
  width: 90px;

  @media(min-width: calc(#{$content-max-width} + 16px)) { // 1rem doesn't work
    padding-left: 0;
  }
}

.navbar__link {
  height: 100%;
  display: none;
  align-items: center;
  padding: 0 0.75rem;
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 0.075s ease-in-out;

  &:hover, &:focus {
    outline: none;    
    background-color: $dark;
  }

  @media (min-width: $mobile-breakpoint) {
    &.navbar__link--desktop {
      display: flex;
      overflow: hidden;
    }
  }

  &.navbar__link--mobile {
    display: flex;
    justify-content: center;
    height: 3rem;

    &.navbar__link--mobile--logout-button {
      border: none;
      border-top: 1px solid $dark;
      width: 100%;
      background-color: transparent;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        background-color: $dark;
      }
    }
  }

  &.navbar__link--active {
    background-color: $dark;
  }  
}


.navbar__menu-button {
  margin-left: auto;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  color: white;
  display: flex;
  background: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  transition: background-color 0.1s ease-in-out;

  &:hover, &:focus {
    outline: none;
    background-color: $accent;
  }

  &.navbar__menu-button--pressed {
    background-color: $dark;

    &:hover, &:focus {
      outline: none;
      background-color: $accent;
    }
  }

  @media (min-width: $mobile-breakpoint) {
    display: none;
  }
}

.navbar__logout-button {
  font-weight: 500;
  letter-spacing: 0.2px;
  display: none;
  margin-left: auto;
  font-size: 0.9rem;
  margin-right: 0.5rem;
  align-self: center;
  padding: 0.5rem;
  cursor: pointer;
  background-color: $dark;
  border-radius: 4px;
  border: none;
  white-space: nowrap;
  color: white;
  transition: background-color 0.1s ease-in-out;

  &:hover, &:focus {
    outline: 0;
    background-color: $accent;
  }

  &:disabled {
    color: #cfcfcf;
    background-color: darken($accent, 20%);
    cursor: default;

    &:hover {
      background-color: darken($accent, 20%);
    }
  }

  @media (min-width: $mobile-breakpoint) {
    display: block;
  }

  @media(min-width: calc(#{$content-max-width} + 16px)) { // 1rem doesn't work
    margin-right: 0;
  }
}

.navbar-mobile-menu {
  position: relative;
  width: 100%;
  z-index: $navbar-z-index;
  background-color: $main;
  box-shadow: 0px 1px 1px rgba(46, 46, 46, 0.2);
  border-top: 1px solid $dark;

  @media (min-width: $mobile-breakpoint) {
    display: none;
  }
}

.mobile-menu-backdrop {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: black;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: $navbar-mobile-menu-backdrop-z-index;

  @media (min-width: $mobile-breakpoint) {
    display: none;
  }
}

.mobile-menu-backdrop-enter {
  opacity: 0;
}

.mobile-menu-backdrop-enter-active {
  opacity: 0.5;
  transition: opacity 0.25s linear;
}

.mobile-menu-backdrop-enter-exit {
  opacity: 0.5;
}

.mobile-menu-backdrop-exit-active {
  opacity: 0;
  transition: opacity 0.25s linear;
}

.disable-body-scroll {
  position: fixed;
  overflow: hidden;

  @media (min-width: $mobile-breakpoint) {
    position: unset;
    overflow: unset;
  }
}