@import '../styles/mixins';

.my-appts-page {
  @include standard-page-wrapper;
}

.appts-list {
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
}