@import './styles/mixins';
@import './styles/colors';

//@import-normalize;

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.1px;
}

table {
  padding: 0;
  border-collapse: collapse;
}

table tr {
  border-top: 1px solid #cccccc;
  background-color: white;
  margin: 0;
  padding: 0;
}

table tr:nth-child(2n) {
  background-color: #f5f5f5;
}

table tr th {
  font-weight: bold;
  border: 1px solid #cccccc;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

table tr td {
  border: 1px solid #cccccc;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

table tr th :first-child, table tr td :first-child {
  margin-top: 0;
}

table tr th :last-child, table tr td :last-child {
  margin-bottom: 0;
}

code {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 0 3px;
  margin: 2px;
}

:focus {
  @include focus-outline;
}

#root {
  width: 100%;
  height: 100%;
}