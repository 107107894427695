@import '../styles/colors';
@import '../styles/constants';
@import '../styles/mixins';

.loading-text {
  @include standard-page-wrapper;
}

.scheduler-page {
  background-color: $main;
  background-image: url('../images/lines-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.scheduler {
  background-color: #fefefe;
  margin: 1rem;
  border-radius: 4px;
  padding: 1.5rem;
  max-width: 400px;
  height: min-content;
  text-align: center;
  box-shadow: 2px 2px 3px rgba(82, 82, 82, 0.5);

  h1, h2, h3 {
    margin-top: 0;
    line-height: 1;
    padding-top: 1rem;
    font-family: 'Roboto Condensed', sans-serif;
  }

  label {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 0.93rem;
  }

  input, select {
    margin-top: 0.1rem;
  }

  @media (min-width: 480px) { // 400px + 4rem + 1rem 
    width: 400px;
    margin: 1rem auto;
  }

  @media(min-width: $mobile-breakpoint) {
    padding: 2rem;
    margin: 3rem auto;
  }
}

.back-arrow-container {
  text-align: left;
  height: 0;
}

.back-arrow {
  position: relative;
  top: -0.5rem;
  left: -0.5rem;
  text-align: left;
  height: min-content;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;

  @media(min-width: $mobile-breakpoint) {
    top: -1rem;
    left: -1rem;
  }

  &:hover {
    > svg {
      opacity: 1;
    }
  }

  > svg {
    opacity: 0.6;
    width: 1.25rem;
  }
}

.scheduler-button {
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem !important;
  letter-spacing: 0.1rem;
  margin: 0.5rem 0 0 0 !important;
  width: 100%;
}


.start-page {
  text-align: center;
}

.start-page__title {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 1;
  margin-bottom: 1rem;
}

.start-page__bctc-logo {
  height: 2.5rem;
}

.action-type-select {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.action-type-select__item {
  width: 5.25rem;
  margin: 0.5rem;
  opacity: 1;
  cursor: pointer;

  &:hover, &.action-type-select__item--selected, &:focus {
    outline: none;

    > .action-type-select__icon-container {
      background-color: rgba($accent, 0.8);

      > .action-type-select__icon-svg {
        opacity: 1;
      }
    }
  }
}

.action-type-select__icon-container {
  padding: 0.6rem; 
  border-radius: 1rem;
  background-color: rgba($accent, 0.25);
  margin-bottom: 0.5rem;
  transition: background-color 0.1s ease-in-out;
}

.action-type-select__icon-svg {
  opacity: 0.6;
  transition: opacity 0.1s ease-in-out;
}

.action-type-select__name {
  text-align: center;
  font-family: 'Roboto Condensed', sans-serif;
}

.selected-time-slot {
  font-size: 1.2rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}