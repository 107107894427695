@import '../styles/constants';

.page-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - #{$navbar-height});
  overflow: auto;
  background-color: #f3f2f7;
}

.page {
  width: 100%;
  height: 100%;
  max-width: $content-max-width;

  &.no-max-width {
    max-width: unset;
  }
}