@import './constants';

@mixin standard-page-wrapper {
  width: 100%;
  max-width: $content-max-width;
  padding: 1rem;

  @media(min-width: $mobile-breakpoint) {
    padding: 2rem;
  }

  @media (min-width: calc(#{$content-max-width} + 64px)) { // 4rem doesn't work
    padding: 2rem 0;
  }
}


@mixin focus-outline {
  outline: rgb(163, 163, 163) solid 4px;
  outline-offset: 4px;
}

@mixin card-shadow {
  box-shadow: 0px 2px 6px #0000002d;
}