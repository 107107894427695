@import '../styles/colors';

.box-page {
  height: 100%;
  width: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: $main;
  background-image: url("../images/lines-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.box-page__logo {
  height: 2.5rem;
  filter: drop-shadow(1px 1px 2px rgba(82, 82, 82, 0.6));
}

.box-page__box {
  width: auto;
  background-color: #fefefe;
  text-align: center;
  box-shadow: 2px 2px 4px rgba(82, 82, 82, 0.6);
  border-radius: 4px;
  padding: 2rem;
  padding-bottom: 1.5rem;
  margin: 0 1rem;

  @media(min-width: 500px) {
    width: 400px;
  }
}

.box-page__after-box {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(82, 82, 82, 0.6);
  font-size: 0.8rem;
  text-align: center;
}

.box-page__after-box__link {
  text-decoration: none;
  color: white;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}
