$main: #4383ab;
$dark: #326585;
$accent: #44ab96;
$subtle: #f9f2ff;

$success-green: #389e38;
$danger-red: #e53855;

$import-full-tag: #ffb017;
$export-full-tag: #da774a;
$storage-empty-tag: indianred;
$export-empty-tag: #683F99;
