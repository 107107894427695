@import '../styles/mixins';

.configuration-page {
  @include standard-page-wrapper;
  overflow-x: hidden;
}

.configuration-page__lower-line {
  display: flex;
  flex-direction: column;

  > div {
    max-width: 400px;

    &:first-child {
      margin-bottom: 1rem;
    }
  }

  @media (min-width: 850px) { // arbitrary; looks decent 
    flex-direction: row;

    > div {
      flex-basis: 400px;

      &:first-child {
        margin-right: 2rem;
        margin-bottom: 0;
      }
    }
  }
}