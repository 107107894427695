@import '../styles/colors';

.unauthenticated-form-page__title {
  margin-bottom: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 2rem;
  font-family: 'Roboto Condensed', sans-serif;
}

.bctc-logo-container {
  margin-top: 1.5rem;
}

.bctc-logo-container__logo {
  height: 2.5rem;
}