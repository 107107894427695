@import '../styles/colors';

.success-message {
  color: $success-green;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.error-message {
  color: $danger-red;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}