@import '../styles/constants';

.modal-backdrop-enter {
  opacity: 0;
}

.modal-backdrop-enter-active {
  opacity: 1;
  transition: opacity 0.15s linear;
}

.modal-backdrop-enter-exit {
  opacity: 1;
}

.modal-backdrop-exit-active {
  opacity: 0;
  transition: opacity 0.15s linear;
}


.modal-enter {
  opacity: 0;
  
  .modal-dialog {
    transform: translate(0, -25%);
  }
}

.modal-enter-active {
  opacity: 1;
  transition: opacity 0.3s linear;

  .modal-dialog {
    transform: translate(0, 0);
    transition: all 0.3s ease-out;
  }
}

.modal-enter-exit {
  opacity: 1;

  .modal-dialog {
    transform: translate(0, 0);
  }
}

.modal-exit-active {
  opacity: 0;
  transition: opacity 0.3s linear;

  .modal-dialog {
    transform: translate(0, -25%);
    transition: all 0.3s ease-out;
  }  
}

.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal-backdrop-z-index;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  z-index: $modal-z-index;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;

  @media (min-width: 464px) {
    margin: 0.5rem auto;
  }
  
  @media (min-width: 532px) {
    margin: 2rem auto;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 4px;
  outline: 0;
}

.modal-content__content {
  padding: 0 1.5rem 1.5rem 1.5rem; // top is accounted for by close-button-container
}

.close-button-container {
  text-align: right;
  height: 1.5rem;
}

.close-button {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: 0.6;
    width: 1.25rem;
    height: 1.25rem;
  }
}
