@import '../styles/colors';
@import '../styles/mixins';

.users-table-container {
  font-size: 0.92rem;

  > table {
    border: 1px solid #ccc;
  }

  @supports (min-width: min-content) {
    @include card-shadow;
    border-radius: 0.5rem;
    overflow: hidden;
    min-width: min-content;

    > table {
      border: none;
    }
  }
}

.users-table {
  width: 100%;
}

.users-table thead {
  th {
    background-color: #f5f5f5;
    color: rgba(black, 0.8);
    border: none;
    font-weight: 500;
    font-size: 0.85rem;
    text-transform: uppercase;
    padding: 1rem 1.2rem;
    letter-spacing: 0.3px;
  }
}

.users-table tr {
  border: none;
}

.users-table td {
  border: none;
  padding: 1rem 1.2rem;
}

.view-button {
  cursor: pointer;
  border: none;
  background: none;
  font-size: 0.9rem;
  color: $accent;

  &:hover {
    text-decoration-line: underline;
  }
}