@import '../styles/colors';
@import '../styles/mixins';

.organize-box {
  padding: 1rem;
  border-radius: 4px;
  background-color: #ffffff;
  @include card-shadow;

  & > hr {
    margin: 0.7rem 0rem;
    border-top: 0;
    border-color: #dbdbdb;
  }
}

.organize-box__title {
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.organize-box__header {
  font-size: 1.1rem;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
}

.organize-box__input-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > label {
    text-align: right;
    flex-basis: 40%;
    padding-right: 0.75rem;
  }
}