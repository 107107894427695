@import '../styles/colors';

.form-button {
  border-radius: 0.5rem;
  border: none;
  padding: 0 0.75rem;
  margin: 0.3rem 0;
  min-height: 45px;
  cursor: pointer;
  color: white;
  background-color: $accent;
  transition: background-color 0.1s ease-in-out;

  font-size: 1rem;

  &:hover {
    background-color: darken($accent, 5%);
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
    background-color: lighten($accent, 5%);
  }

  &.form-button--success {
    background-color: $success-green;

    &:hover {
      background-color: darken($success-green, 5%);
    }
  
    &:disabled {
      cursor: default;
      opacity: 0.7;
      background-color: lighten($success-green, 5%);
    }
  }

  &.form-button--danger {
    background-color: $danger-red;
    
    &:hover {
      background-color: darken($danger-red, 5%);
    }
  
    &:disabled {
      cursor: default;
      opacity: 0.7;
      background-color: lighten($danger-red, 5%);
    }
  }
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-input, .PhoneInputInput {
  width: 100%;
  height: 45px;
  margin: 0.4rem 0;
  padding: 0.2rem 0.75rem 0.2rem 0.75rem;
  border: 2px solid #dddddd;
  border-radius: 0.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  transition: border-color 0.09s ease-in-out;

  &:focus {
    outline: none;
    border-color: #aaaaaa;
  }

  &::placeholder {
    color: #aaaaaa;
  }

  &:disabled {
    cursor: default;
    color: graytext;
  }

  &.touched:invalid:not(.disable-invalid-highlighting) {
    border-color: $danger-red;
  }
}

.PhoneInput.invalid.touched {
  .PhoneInputInput {
    border-color: $danger-red;
  }
}

.form-select {
  width: 100%;
  height: 45px;
	font-size: 1rem;
  padding: 0.2rem 0.75rem 0.2rem 0.75rem;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0.4rem 0;
	border: 2px solid #dddddd;
  border-radius: 0.5rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  appearance: none;
  background-color: #ffffff;
  -webkit-appearance: none;

  &:disabled {
    cursor: default;
    color: rgb(90, 90, 90);
  }
  
  &:focus {
    outline: none;
    border-color: #aaaaaa;
  }

  &.touched:invalid:not(.disable-invalid-highlighting) {
    border-color: $danger-red;
  }
}

.form-note {
  text-align: right;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}