@import '../styles/mixins';

.settings-page {
  @include standard-page-wrapper;
}

.settings-page__user-card {
  border-radius: 1rem;
  padding: 1.5rem;
  max-width: 400px;
  background-color: #ffffff;
  @include card-shadow;
  margin-bottom: 2rem;
}

.settings-page__user-card__header {
  margin-top: 0;
  text-align: center;
}