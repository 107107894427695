@import '../styles/colors';

.restrictions-table {
  display: grid;
  grid-template-columns: min-content repeat(24, 3rem);
}

.restrictions-table-hour {
  text-align: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
}

.restrictions-table-date {
  padding-right: 0.75rem;
  padding-top: 0.75rem;
  border-right: 1px solid #ccc;
  white-space: nowrap;
  text-align: right;
}

.restrictions-table-cell {
  background-color: #ffffff;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.05s ease-in-out;
  overflow: hidden;

  cursor: pointer;
  &:hover {
    background-color: $subtle;
  }

  &.restrictions-table-cell--selected {
    background-color: $subtle;  
    background-color: darken($subtle, 3);
    color: inherit !important;
    border-right: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    animation: pulse 2.5s infinite;
  }

  &:focus {
    outline: none;
    border-right: 2px solid darken(#cccccc, 4);
    border-bottom: 2px solid darken(#cccccc, 4);
    border-top: 1px solid darken(#cccccc, 4);
    border-left: 1px solid darken(#cccccc, 4);
  }

  &.restrictions-table-cell--disabled {
    color: graytext;
    cursor: default;

    background-color: #efefef;

    &:focus {
      outline: none;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-top: none;
      border-left: none;
    }
  }
}

.gate-capacity-value {
  &.gate-capacity-value--default {
    color: graytext;
  }

  &.gate-capacity-value--template {
    color: $dark !important;
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(darken($subtle, 4), 1);
    border-color: #ccc;
  }
  50% {
    background-color: rgba($subtle, 0.5);
    border-color: rgba(184, 184, 184, 0.5);
  }
  100% {
    background-color: rgba(darken($subtle, 4), 1);
    border-color: #ccc;
  }
}

.restrictions-table-input-cell {
  border: none;
  outline: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 1rem;
}
