@import '../styles/constants';
 
.dashboard-page {
  height: 100%;
  width: 100%;
  max-width: $content-max-width;
  display: flex;
  flex-direction: column;

  @media (min-width: $mobile-breakpoint) {
    flex-direction: row;
  }
}

.organize-box-col {
  flex-shrink: 0;
  padding: 1rem 1rem 0 1rem;
  overflow-y: auto;

  @media (min-width: $mobile-breakpoint) {
    padding: 2rem 1rem 0 2rem;
    min-width: 350px;
    width: 350px;
  }

  @media (min-width: calc(#{$content-max-width} + 64px)) { // 4rem doesn't work
    padding-left: 4px;
  }
}

.organize-box-container {
  padding-bottom: 0.5rem;

  @media (min-width: $mobile-breakpoint) {
    padding-bottom: 2rem;
  }
}

.appts-col {
  flex-grow: 1;
  padding: 1rem 1rem 0 1rem;

  @media (min-width: $mobile-breakpoint) {
    padding: 2rem 2rem 0 0.5rem;
    height: calc(100% - 4rem);
    overflow-y: auto;
  }

  @media (min-width: calc(#{$content-max-width} + 64px)) { // 4rem doesn't work
    padding: 2rem 4px;
  }
}

.appts-container {
  height: 100%;
  display: grid;
  grid-column-gap: 1.2rem;
  grid-row-gap: 1.2rem;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: min-content;
  padding-bottom: 1rem;

  @media (min-width: $mobile-breakpoint) {
    padding-bottom: 2rem;
  }
 
  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
